html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
footer,
header,
nav,
time,
section {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    /* font-weight: inherit;
    font-style: inherit;
    font-size: 100%;
    font-family: inherit; */
    vertical-align: baseline;
}

body {
    -webkit-font-smoothing: antialiased;
}


/* remember to define focus styles! */

:focus {
    outline: 0;
}


/* tables still need 'cellspacing="0"' in the markup */

table {
    border-collapse: separate;
    border-spacing: 0;
}

caption,
th,
td {
    text-align: left;
    font-weight: normal;
}


/**********************************************************************/


/* 2. Needed for rendering HTML5 properly in current browsers         */


/**********************************************************************/

article,
aside,
footer,
header,
nav,
section {
    display: block;
}


/**********************************************************************/


/* 3. Some basic helper styles                                        */


/**********************************************************************/

.invisible,
legend {
    position: absolute;
    left: -9999em;
}

.clear {
    display: block;
    width: 100%;
    clear: both;
}

.unsichtbar {
    position: absolute;
    top: -9999px;
    left: -9999px;
    height: 1px;
    width: 1px;
    display: block;
}


/**********************************************************************/


/* 4. Basic styles against the reset                                  */


/**********************************************************************/

code,
kbd,
var,
tt {
    font-family: Consolas, "Courier New", Courier, sans-serif;
}

p code,
p kbd,
p var,
p tt {
    line-height: 1;
}

strong,
dt {
    font-weight: bold;
}

em,
cite {
    font-style: italic;
}

abbr[title] {
    border-bottom: 1px dotted #888;
    cursor: help;
}

a abbr {
    cursor: pointer;
}

del {
    text-decoration: line-through;
}

ins {
    border-bottom: 1px dashed #888;
    text-decoration: none;
}

sub {
    vertical-align: text-bottom;
}

sup {
    vertical-align: text-top;
}

sub,
sup {
    font-size: 75%;
    line-height: 1;
}

ol {
    list-style-type: decimal;
}

ul {
    list-style-type: disc;
}

input,
textarea,
select {
    font-size: 1em;
    margin: 0;
    border: 0;
    padding: 0;
}

label {
    font-weight: normal;
}

label,
button,
input[type=button],
input[type=submit] {
    cursor: pointer;
}

fieldset {
    display: block;
    margin: 0;
}


/* This positions an unstyled legend element */


/* correctly in IE6 + IE7                    */

* html legend,
*:first-child+html legend {
    position: relative;
    left: -10px;
}

@font-face {
    font-family: 'Arvo Regular';
    src: local('Arvo Regular'), local('ArvoRegular'), url('/fonts/arvo_regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Arvo Gruen';
    src: local('Arvo Gruen'), local('ArvoGruen'), url('/fonts/arvo_gruen.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PT Sans';
    src: local('PT Sans'), local('PTSans-Regular'), url('/fonts/ptsans-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PT Sans Bold';
    src: local('PT Sans Bold'), local('PTSans-Bold'), url('/fonts/ptsans-bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}


/*********************
04. LINK STYLES
*********************/

a {
    color: #46962b;
    text-decoration: none;
}

a:hover {
    color: #0a321e;
    text-decoration: underline;
}

a:visited {
    color: #46962b;
}

a span.button,
span.button a {
    display: inline-block;
    background: #46962b;
    color: #fff;
    padding: 0.5em;
    font-family: 'Arvo Regular', 'Arvo Gruen', Trebuchet, Helvetica Neue, Helvetica, Arial, Verdana, sans-serif;
    border-radius: 0.3em;
}

a:hover span.button,
span.button a:hover {
    background: #0a321e;
}


/******************************************************************
05. H1, H2, H3, H4, H5 STYLES
******************************************************************/

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.4em;
    margin-bottom: 0.3em;
    font-family: 'Arvo Regular', 'Arvo Gruen', Trebuchet, Helvetica Neue, Helvetica, Arial, Verdana, sans-serif;
    font-weight: normal;
    color: #0a350a;
    text-align: left;
}

h1 {
    font-size: 1.8em;
    margin-bottom: 0em;
}

h1.page-title {
    margin-bottom: 0.7em;
}

h2 {
    font-size: 1.6em;
}

h3 {
    font-size: 1.5em;
    color: #5dae59;
}

h4 {
    font-size: 1.3em;
}

h5 {
    font-size: 1.1em;
}

h6 {
    font-size: 1em;
}


/*********************
06. HEADER SYTLES
*********************/



/* #header.noimg {background: url(../images/header_mobile.jpg);background-size: cover;} */



#header h1 a {
    color: #fff;
    text-decoration: none;
}

#header h2 {
    font-size: 1em;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
    color: #ffe000;
    margin: 5px 0 0 0;
    text-align: center;
}

#header #logo {
    display: none;
}

#header .searchform {
    display: none;
    position: absolute;
    background: #000;
    background: rgba(0, 0, 0, 0.7);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

#header .searchform label {
    display: none;
}

#header .searchform input#search {
    border: 1px solid #333;
    padding: 1em;
    box-sizing: border-box;
    box-shadow: 0 0 10px #888 inset;
    font-family: 'Arvo Regular', 'Arvo Gruen', Trebuchet, "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif;
    background: #fff;
    color: #333;
    font-size: 1em;
    width: 80%;
    margin: 0 10%;
    position: absolute;
    top: 41%;
}

#header .searchform .button-submit {
    display: none;
}

#portal,
#breadcrumb,
#header .searchform {
    display: none;
}

#header .hgroup {
    border-top: 0.2em solid #fff;
    border-bottom: 0.2em solid #fff;
    padding: 0.5em 0;
}

#header .headerimg {
    display: none;
}

#disclaimer {
    text-align: center;
    color: #5dae59;
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.4);
    text-transform: uppercase;
    font-family: 'Arvo Gruen', 'Arvo Regular', Trebuchet, "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif;
    font-size: 1.5em;
    margin-bottom: 1em;
    background-color: rgb(239, 242, 237);


}

#fullpage {
    background-image: url(/images/body_bg.jpg);
    background-position: center top;
    background-size: cover !important;
    background-repeat: repeat;
    background-attachment: fixed;
}

body {
    background-color: rgb(239, 242, 237);
}


/* body {
  margin: 0;
  font-family: inherit;

    font-family: Arvo_Gruen, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */