code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#header .hgroup {
    border-top: 0.2em solid #fff;
    border-bottom: 0.2em solid #fff;
    padding: 0.5em 0;
}

.App {
    text-align: center;
    /* font-family: " Arial, sans-serif" */
    /* font-family: "serif" */
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

form {
    font-size: 110%
}

label {
    display: block;
    color: black;
    margin: 16px 0 3px;
}

fieldset label {
    display: inline-block;
    /* font-family: Calibri, sans-serif; */
    margin: 10px 15px 0px 0px;
}

input[type=text],
input[type=email],
input[type=tel],
input[type=date],
textarea,
select {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    background-color: #f8f8f8;
    border: solid 1px #46962b;
    /* font-family:Calibri, sans-serif; */
    font-size: 1.1em;
}

textarea {
    resize: vertical;
    min-height: 200px;
}

fieldset {
    border: none;
    margin: 5px 0px 25px 0px;
    padding: 0px 0;
}

legend {
    color: white;
    background-color: tan;
    display: block;
    width: 100%;
    padding: 3px;
}

input[type=checkbox] {
    background-color: red;
    border: solid 1px red;
    color: red;
    outline: red;
}


/* BUTTONS */

input[type=submit] {
    background-color: forestgreen;
    color: white;
    border: none;
    padding: 12px 20px;
    font-size: 110%;
    width: 300px;
    margin-top: 20px;
    font-family: Calibri, sans-serif;
}


/* Reset Button: minimize the appearance */

input[type=reset] {
    background-color: transparent;
    border: none;
    text-decoration: underline;
    cursor: pointer;
    margin: 20px 0;
    color: olive;
}

input[type=submit].delete {
    background-color: red;
    margin: 20px;
}

input[type=sumbit]:hover.delete,
input[type=submit]:focus.delete {
    background-color: rgb(102, 0, 0);
}


/* hovers and tabs */

input[type=text]:hover,
input[type=email]:hover {
    background-color: #c2e8bc;
}

input[type=text]:focus,
input[type=email]:focus {
    outline: 2px solid #4e6c49;
}

input[type=sumbit]:hover,
input[type=submit]:focus {
    background-color: limegreen;
}


/* responsive bonus round */

@media screen and (min-width:450px) {
    /*  2col  */
    .column {
        width: 50%;
        float: left;
        padding: 3%;
        box-sizing: border-box;
    }
}

.navigation_button {
    background-color: white;
    color: forestgreen;
    border: 1px solid forestgreen;
    padding: 12px 20px;
    font-size: 110%;
    width: 300px;
    margin: 20px;
    font-family: Calibri, sans-serif;
    cursor: pointer;
}


/* #header {
  background-color: forestgreen;
  width: 100%;
  height: 100px;
  
} */

#header .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#header .logo_title {
    display: flex;
    flex-direction: row;
}

#header #header_logo {
    width: 100px;
    height: 100px;
}

#header .title {
    color: white;
    /* font-family: sans-serif; */
    font-size: 40px;
    font-weight: bold;
    margin-top: 30px;
}

#header .links {
    margin: auto 20px;
}

#header .links a {
    color: white;
    font-size: 20px;
}




#map_button_groups {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#loading {
    font-family: 'Arvo Gruen', 'Arvo Regular', Trebuchet, "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif;
    text-transform: uppercase;
    color: #000000;
    font-weight: 400;
    font-size: 20px;
}


.mapboxgl-map {
    height: 70vh;
    width: 100vw;
}



#detailed_view {
    margin: 2em;
}


.mapview {
    display: flex;
    flex-direction: row;
    background-color: rgb(239, 242, 237);
    padding: 0em;
    margin: 0em 0em;
}

#header {
    margin: 0;
    margin-bottom: 0em;
    padding: 1em 1em;
    position: relative;
}

#header #header_logo {
    width: 50px;
    height: 50px;
}

#header h1 {
    text-align: center;
    color: #fff;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
    text-transform: uppercase;
    font-family: 'Arvo Gruen', 'Arvo Regular', Trebuchet, "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif;
    font-size: 1.5em;
}


#detailed_view { 
    margin: 3em auto;
    position: absolute;
    left: 0;
    right:0;
    
    /* display:none; */
    background-color: white;
    padding: 1em;
    border: 2px solid grey;
    z-index: 1000;
    /* height: 63%;
    overflow-y: scroll; */
    width:90%;
}




#close_detailed_view {
    position: relative;
    
    cursor: pointer;
    text-align:right;
}

#detailed_view_content {
    position:relative;
}
  

  @media screen and (min-width: 875px) {
    #detailed_view  {
        display:block;
        position:relative;
        background: none;
        padding: 0em;
        border: none;
        height: 100%;
        /* overflow-y: auto; */
        
        
    }


    @media screen and (min-width: 1024px) {
        #detailed_view  {
        margin: 2em 0em;
        }

        .mapview {
            padding: 2em;
            margin: 0em 2em;
        }

          

    #header {
        margin: 0;
        margin-bottom: 1em;
        padding: 2em 4em;
        position: relative;
    }

    #header #header_logo {
        width: 100px;
        height: 100px;
    }


    #header h1 {
       
        font-size: 2.5em;
    }
    }

    #close_detailed_view {
        display: none;
    }

  


  

  }


 


#detailed_view h1 {
    font-family: 'Arvo Gruen', 'Arvo Regular', Trebuchet, "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif;
    text-transform: uppercase;
    color: #46962b;
    font-weight: 400;
    font-size: 20px;
}

#detailed_view h2 {
    font-family: 'Arvo Regular', Trebuchet, "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif;
    color: #46962b;
    font-weight: 200;
   
   
    font-size: 40px;
}

#leafletmap {
    width: 100%;
    height: 700px;
}

@media screen and (min-width: 300px) {
    #detailed_view h2 {
        font-size: 10px;
    }
  }
  

  @media screen and (min-width: 600px) {
    #detailed_view h2 {
        font-size: 20px;
    }
  }

  @media screen and (min-width: 875px) {

    #leafletmap {
        width: 500px;
        height: 700px;
    }
    #detailed_view {
        padding:1em;
        height: 700px;
        overflow-y: scroll;
    }

    #detailed_view h2 {
        font-size: 20px;
        
    }
  }

  @media screen and (min-width: 1000px) {
    #detailed_view h2 {
        font-size: 25px;
    }
  }

@media screen and (min-width: 1400px) {
    #detailed_view h2 {
        font-size: 30px;
    }
  }

  

 


  


#detailed_view .title {
    font-family: 'Arvo Regular', Trebuchet, "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif;
    color: #46962b;
    font-weight: 200;
    font-size: 20px;
}

#detailed_view h3 {
    font-family: 'Arvo Regular', Trebuchet, "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif;
    color: #46962b;
    font-weight: 200;
    font-size: 20px;
    margin-top: 2em;
}

#detailed_view .detail_value {
    font-family: 'PT Sans', 'Trebuchet MS', Helvetica Neue, Helvetica, Arial, Verdana, sans-serif;
    font-size: 100%;
    line-height: 1.5em;
}

#detailed_view .detail_value a {
    font-family: 'PT Sans', 'Trebuchet MS', Helvetica Neue, Helvetica, Arial, Verdana, sans-serif;
    font-size: 100%;
    line-height: 1.5em;
    text-decoration: underline;
    color: #000000;
}


#detailed_view .close {
    font-family: 'Arvo Regular', Trebuchet, "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif;
    color: #282c34;
    font-weight: 200;
    font-size: 20px;
}

#matrix_table {
    border-collapse: collapse;
    overflow: hidden;
}

#matrix_table th {
    background-color: #cccccc;
}

#matrix_table tr:hover,
#matrix_table tr:hover td.topic {
    background-color: forestgreen !important;
    color: white;
}

#matrix_table,
#matrix_table th,
#matrix_table td {
    border: 1px solid #888888;
}

#matrix_table th,
#matrix_table td {
    width: 150px;
    text-align: center;
    padding: 20px 10px;
    position: relative;
}

#matrix_table td:hover::after,
#matrix_table th:hover::after {
    content: "";
    position: absolute;
    background-color: forestgreen;
    color: white;
    left: 0;
    top: -5000px;
    height: 10000px;
    width: 100%;
    z-index: -1;
}

#matrix_table td.topic {
    width: 200px;
    font-weight: bold;
    text-align: center;
    height: 30px;
    background-color: #efefef;
}

.delete_container {
    margin: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.delete_text {
    width: 800px;
    margin: 100px auto;
    font-size: 20px;
    font-weight: bold;
}

.delete_options {
    display: flex;
    flex-direction: row;
    margin: 0px auto;
}

#admin_page {
    display: flex;
    flex-direction: column;
}

#admin_feedback_bar {
    position: fixed;
    bottom: 0;
    width: 100%;
}

#admin_feedback_bar_container {
    display: flex;
    padding: 20px;
    font-size: 1.2rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    border-radius: 4px;
    letter-spacing: 0.01071em;
    width: 100%;
}

#admin_feedback_bar .success {
    color: white;
    background-color: forestgreen;
    border-top: 2px solid rgb(30, 70, 32);
}

#admin_feedback_bar .error {
    color: white;
    border-top: 2px solid rgb(53, 15, 15);
    background-color: red;
}

#admin_feedback_bar .warning {}

.input_error {
    color: red;
}

.dropzone {
    width: 100%;
    height: 100px;
    background-color: #efefef;
    display: flex;
    justify-content: center;
    align-items: center;
}

#admin_page {
    font-family: 'Arvo Regular', Trebuchet, "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif;
    color: #000000;
}